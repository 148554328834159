
import { Vue, Component, Prop } from "vue-property-decorator";
import Categorias from "@/components/Talleres/Categorias.vue";

@Component({
  components: {
    Categorias
  }
})
export default class CategoriasView extends Vue {
  private mounted() {}
}
