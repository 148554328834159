
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import Debounce from "debounce-decorator";

interface dataItem {
  id: number | any;
  nombre: string | any;
}
interface dataFilter {
  modalidad: Array<dataItem>;
  lugares: Array<dataItem>;
  horarios: Array<dataItem>;
  formato: Array<dataItem>;
  dias: Array<dataItem>;
  genero: Array<dataItem>;
  publico: Array<dataItem>;
  gratuito: Array<dataItem>;
  edadMin: number | any;
  edadMax: number | any;
  texto: string | any;
}

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

interface dataBannerMobile {
  id: number;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {
    FormValidator
  }
})
export default class TalleresComponent extends Mixins(FormValidator) {
  private lastHover = "";
  private slide = 0;
  private textoBuscar = "";
  private textTaller = "";
  private slideIndex: number = 1;
  private textLugar = "";
  private edad = {
    min: 5,
    max: 120
  };
  private aBanners: Array<dataBanner> = [];
  private lugaresGroup: Array<any> = [];
  private dataLugares: Array<any> = [];
  private horarioGroup: Array<any> = [];
  private formatoGroup: Array<any> = [];
  private diasGroup: Array<any> = [];
  private generoGroup: Array<any> = [];
  private tipoGroup: Array<any> = [];
  private publicoGroup: Array<any> = [];
  private gratuitoGroup: Array<any> = [];
  private arrayFiltrado: Array<any> = [];
  private arrayFiltradoTalleres: Array<any> = [];
  private arrayFiltradoLugares: Array<any> = [];
  private dataTalleres: Array<any> = [];
  private talleres: Array<any> | null = null;
  private dialogFiltros: boolean = false;
  private infoTalleres: dataFilter = {
    modalidad: [],
    lugares: [],
    horarios: [],
    formato: [],
    dias: [],
    genero: [],
    publico: [],
    gratuito: [],
    edadMin: 0,
    edadMax: 0,
    texto: ""
  };

  private lastFiltro = "";
  private currentPage = 1;
  private total = 0;

  private arrayFormato: Array<any> = [
    {
      label: this.$t("fields.on-site").toString(),
      value: 0
    },
    {
      label: this.$t("fields.online").toString(),
      value: 1
    },
    {
      label: this.$t("fields.mixed").toString(),
      value: 2
    }
  ];

  private arrayTipo: Array<any> = [
    {
      label: this.$t("fields.workshop").toString(),
      value: 0
    },
    {
      label: this.$t("fields.actividades").toString(),
      value: 1
    },
    {
      label: "Servicio",
      value: 2
    },
    {
      label: "Actividad masiva",
      value: 3
    }
  ];

  private arrayGenero: Array<any> = [
    {
      label: this.$t("fields.all").toString(),
      value: 0
    },
    {
      label: this.$t("fields.man").toString(),
      value: 1
    },
    {
      label: this.$t("fields.woman").toString(),
      value: 2
    }
  ];

  private arrayPublico: Array<any> = [
    {
      label: this.$t("fields.all").toString(),
      value: 0
    },
    {
      label: this.$t("fields.only-neighbors").toString(),
      value: 1
    }
  ];

  private arrayGratuito: Array<any> = [
    {
      label: this.$t("fields.paid").toString(),
      value: 0
    },
    {
      label: this.$t("fields.free").toString(),
      value: 1
    }
  ];

  private arrayDias: Array<any> = [
    {
      label: this.$t("fields.monday").toString(),
      value: 1
    },
    {
      label: this.$t("fields.tuesday").toString(),
      value: 2
    },
    {
      label: this.$t("fields.wednesday").toString(),
      value: 3
    },
    {
      label: this.$t("fields.thursday").toString(),
      value: 4
    },
    {
      label: this.$t("fields.friday").toString(),
      value: 5
    },
    {
      label: this.$t("fields.saturday").toString(),
      value: 6
    },
    {
      label: this.$t("fields.sunday").toString(),
      value: 7
    }
  ];

  private arrayHorario: Array<any> = [
    {
      label: this.$t("fields.morning").toString(),
      value: 0
    },
    {
      label: this.$t("fields.afternoon").toString(),
      value: 1
    },
    {
      label: this.$t("fields.evening").toString(),
      value: 2
    }
  ];
  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.round(this.total / 12)) {
      this.buscar();
    }
  }

  @Watch("diasGroup")
  private selectDias(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.dias = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayDias.length; j++) {
          if (newValue[i] == this.arrayDias[j].value) {
            this.infoTalleres.dias.push({
              id: this.arrayDias[j].value,
              nombre: this.arrayDias[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.dias = [];
    }
    this.buscar();
  }

  @Watch("generoGroup")
  private selectGenero(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.genero = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayGenero.length; j++) {
          if (newValue[i] == this.arrayGenero[j].value) {
            this.infoTalleres.genero.push({
              id: this.arrayGenero[j].value,
              nombre: this.arrayGenero[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.genero = [];
    }
    this.buscar();
  }

  @Watch("publicoGroup")
  private selectPublico(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.publico = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayPublico.length; j++) {
          if (newValue[i] == this.arrayPublico[j].value) {
            this.infoTalleres.publico.push({
              id: this.arrayPublico[j].value,
              nombre: this.arrayPublico[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.publico = [];
    }
    this.buscar();
  }

  @Watch("gratuitoGroup")
  private selectGratuito(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.gratuito = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayGratuito.length; j++) {
          if (newValue[i] == this.arrayGratuito[j].value) {
            this.infoTalleres.gratuito.push({
              id: this.arrayGratuito[j].value,
              nombre: this.arrayGratuito[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.gratuito = [];
    }
    this.buscar();
  }

  @Watch("edad")
  private selectEdad(newValue: any) {
    this.infoTalleres.edadMin = newValue.min;
    this.infoTalleres.edadMax = newValue.max;
    this.buscar();
  }

  @Watch("textoBuscar")
  private selectTextoBuscar(newValue: any) {
    this.infoTalleres.texto = newValue;
    this.buscar();
  }

  @Watch("lugaresGroup")
  private selectLugar(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.lugares = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.dataLugares.length; j++) {
          if (newValue[i] == this.dataLugares[j].value) {
            this.infoTalleres.lugares.push({
              id: this.dataLugares[j].value,
              nombre: this.dataLugares[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.lugares = [];
    }
    this.buscar();
  }

  @Watch("formatoGroup")
  private selectFormato(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.formato = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayFormato.length; j++) {
          if (newValue[i] == this.arrayFormato[j].value) {
            this.infoTalleres.formato.push({
              id: this.arrayFormato[j].value,
              nombre: this.arrayFormato[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.formato = [];
    }
    this.buscar();
  }

  @Watch("horarioGroup")
  private selectHorario(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.horarios = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayHorario.length; j++) {
          if (newValue[i] == this.arrayHorario[j].value) {
            this.infoTalleres.horarios.push({
              id: this.arrayHorario[j].value,
              nombre: this.arrayHorario[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.horarios = [];
    }
    this.buscar();
  }

  @Watch("tipoGroup")
  private selectTipo(newValue: any) {
    if (newValue && newValue.length > 0) {
      this.infoTalleres.modalidad = [];
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.arrayTipo.length; j++) {
          if (newValue[i] == this.arrayTipo[j].value) {
            this.infoTalleres.modalidad.push({
              id: this.arrayTipo[j].value,
              nombre: this.arrayTipo[j].label
            });
          }
        }
      }
    } else {
      this.infoTalleres.modalidad = [];
    }
    this.buscar();
  }

  get filtroLugares() {
    return this.textLugar;
  }

  set filtroLugares(value) {
    value = value.toLowerCase();
    this.arrayFiltradoLugares = this.dataLugares.filter(
      item => item.label.toLowerCase().indexOf(value) !== -1
    );
    this.textLugar = value;
  }
  @Watch("currentPage")
  private currentPageBuscar(newValue: any) {
    this.buscar();
  }
  private mounted() {
    this.buscar();
    this.getLugares();
    this.autoscroll();
    if (
      this.$store.state.user.id &&
      this.$store.state.user.id != "" &&
      (!this.$store.state.user.fecha_nacimiento ||
        this.$store.state.user.sexo == null)
    ) {
      this.$swal({
        icon: "warning",
        title: "Completar Datos",
        text: "Para una mejor experiencia, completar los datos faltantes",
        html: "Para una mejor experiencia, completar los datos faltantes",
        allowOutsideClick: false,
        confirmButtonColor: "#346a82",
        cancelButtonColor: "#d33",
        focusConfirm: false,
        confirmButtonText:
          '<a class="" style="color: #fff;"  href="/editar">Completar Datos</a>'
      });
    }
  }

  private updated() {
    this.showSlides(this.slideIndex);
  }

  private verTaller(item) {
    if (
      this.$router.currentRoute.name == "PerfilTaller" &&
      this.$router.currentRoute.params.name == item.nombre_web
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PerfilTaller",
          params: { nombre: item.nombre_web, id: item.id }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  @Debounce(500)
  private buscar() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    var path = "?nombre=" + this.$router.currentRoute.params.nombre;
    if (this.infoTalleres.dias && this.infoTalleres.dias.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.dias.length; i++) {
        if (i == 0) {
          path = path + "dias[]=" + this.infoTalleres.dias[i].id;
        } else {
          path = path + "&dias[]=" + this.infoTalleres.dias[i].id;
        }
      }
    }

    if (this.infoTalleres.genero && this.infoTalleres.genero.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.genero.length; i++) {
        if (i == 0) {
          path = path + "genero[]=" + this.infoTalleres.genero[i].id;
        } else {
          path = path + "&genero[]=" + this.infoTalleres.genero[i].id;
        }
      }
    }

    if (this.infoTalleres.publico && this.infoTalleres.publico.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.publico.length; i++) {
        if (i == 0) {
          path = path + "publico[]=" + this.infoTalleres.publico[i].id;
        } else {
          path = path + "&publico[]=" + this.infoTalleres.publico[i].id;
        }
      }
    }
    if (this.infoTalleres.gratuito && this.infoTalleres.gratuito.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.gratuito.length; i++) {
        if (i == 0) {
          path = path + "gratuito[]=" + this.infoTalleres.gratuito[i].id;
        } else {
          path = path + "&gratuito[]=" + this.infoTalleres.gratuito[i].id;
        }
      }
    }

    if (this.infoTalleres.edadMin > 0 && this.infoTalleres.edadMin > 5) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "edadMin=" + this.infoTalleres.edadMin;
    }

    if (this.infoTalleres.edadMax > 0 && this.infoTalleres.edadMax < 120) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "edadMax=" + this.infoTalleres.edadMax;
    }

    if (this.infoTalleres.texto != "") {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "texto=" + this.infoTalleres.texto;
    }
    if (this.infoTalleres.modalidad && this.infoTalleres.modalidad.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.modalidad.length; i++) {
        if (i == 0) {
          path = path + "modalidad[]=" + this.infoTalleres.modalidad[i].id;
        } else {
          path = path + "&modalidad[]=" + this.infoTalleres.modalidad[i].id;
        }
      }
    }

    if (this.infoTalleres.lugares && this.infoTalleres.lugares.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.lugares.length; i++) {
        if (i == 0) {
          path = path + "lugares[]=" + this.infoTalleres.lugares[i].id;
        } else {
          path = path + "&lugares[]=" + this.infoTalleres.lugares[i].id;
        }
      }
    }
    if (this.infoTalleres.formato && this.infoTalleres.formato.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.formato.length; i++) {
        if (i == 0) {
          path = path + "formato[]=" + this.infoTalleres.formato[i].id;
        } else {
          path = path + "&formato[]=" + this.infoTalleres.formato[i].id;
        }
      }
    }
    if (this.infoTalleres.horarios && this.infoTalleres.horarios.length > 0) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }
      for (let i = 0; i < this.infoTalleres.horarios.length; i++) {
        if (i == 0) {
          path = path + "horarios[]=" + this.infoTalleres.horarios[i].id;
        } else {
          path = path + "&horarios[]=" + this.infoTalleres.horarios[i].id;
        }
      }
    }
    if (this.lastFiltro != path) {
      this.currentPage = 1;
      this.lastFiltro = path;
    }
    if (path == "") {
      path = path + "?pagina=" + this.currentPage;
    } else {
      path = path + "&pagina=" + this.currentPage;
    }
    this.dialogFiltros = false;
    this.$axios
      .get("categoria" + path)
      .then((res: any) => {
        this.talleres = res.data.talleres;
        this.total = res.data.total;
        if (this.talleres) {
          for (let i = 0; i < this.talleres.length; i++) {
            if (this.talleres[i].foto) {
              this.talleres[i].foto =
                "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
                this.talleres[i].foto;
            } else {
              this.talleres[i].foto = require("@/assets/img/default.png");
            }
          }
        } else {
          this.talleres = [];
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.talleres = [];
      });
  }

  private vaciarFiltro() {
    this.tipoGroup = [];
    this.diasGroup = [];
    this.generoGroup = [];
    this.publicoGroup = [];
    this.gratuitoGroup = [];
    this.edad.min = 5;
    this.infoTalleres.edadMin = 0;
    this.edad.max = 120;
    this.infoTalleres.edadMax = 0;
    this.buscar();
  }

  private vaciarDatoDias(dato: any) {
    this.diasGroup.splice(dato, 1);
    this.buscar();
  }
  private vaciarDatoLugar(dato: any) {
    this.lugaresGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoFormato(dato: any) {
    this.formatoGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoHorario(dato: any) {
    this.horarioGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoModalidad(dato: any) {
    this.tipoGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoGenero(dato: any) {
    this.generoGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoPublico(dato: any) {
    this.publicoGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarDatoGratuito(dato: any) {
    this.gratuitoGroup.splice(dato, 1);
    this.buscar();
  }

  private vaciarEdadMin(dato: any) {
    this.edad.min = 5;
    this.infoTalleres.edadMin = 0;
    this.buscar();
  }

  private vaciarEdadMax(dato: any) {
    this.edad.max = 120;
    this.infoTalleres.edadMax = 0;
    this.buscar();
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private toLink(link) {
    if (link != "#") {
      window.open(link, "_self");
    }
  }
  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }
  // Fin Funciones del banner

  private hoverTaller(taller) {
    this.lastHover = taller.id;
  }
  private checkOver(taller) {
    if (this.$q.screen.xs) {
      if (taller.modalidad == 0) {
        return " bottom_card_color_taller";
      } else if (taller.modalidad == 1) {
        return " bottom_card_color_actividad";
      } else {
        return " bottom_card_color_serivicio";
      }
    } else {
      if (this.lastHover == taller.id) {
        if (taller.modalidad == 0) {
          return " bottom_card_color_taller";
        } else if (taller.modalidad == 1) {
          return " bottom_card_color_actividad";
        } else {
          return " bottom_card_color_servicio";
        }
      } else {
        return "";
      }
    }
  }
  private getLugares() {
    this.$axios
      .get("lugares")
      .then((res: any) => {
        const responseData = res.data;
        responseData.forEach(p => {
          this.dataLugares.push({
            value: p.id,
            label: p.nombre
          });
        });
        this.arrayFiltradoLugares = this.dataLugares;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
