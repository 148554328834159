import { render, staticRenderFns } from "./Categorias.vue?vue&type=template&id=59935d86&scoped=true&"
import script from "./Categorias.vue?vue&type=script&lang=ts&"
export * from "./Categorias.vue?vue&type=script&lang=ts&"
import style0 from "./Categorias.vue?vue&type=style&index=0&id=59935d86&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59935d86",
  null
  
)

export default component.exports